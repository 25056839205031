import { useState, useEffect } from "react";
import { Row, Col, Button, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import co2 from "../../../assets/images/icons/gp2-icons/CarbonFootprint.svg";
import energy from "../../../assets/images/icons/gp2-icons/Energy_1.svg";
import water from "../../../assets/images/icons/gp2-icons/Water_1.svg";
import payback from "../../../assets/images/icons/gp2-icons/Payback.svg";
import savings from "../../../assets/images/icons/gp2-icons/Savings.svg";
import investment from "../../../assets/images/icons/gp2-icons/TotalInvestment.svg";
import collapse from "../../../assets/images/icons/gp2-icons/collapse.svg";
import expand from "../../../assets/images/icons/gp2-icons/expand.svg";
import ProjectsCompleted from "../../../assets/images/icons/gp-dashboard-icons/ProjectsCompleted.svg";
import { numberFormatToWhole } from "../../../helpers";
import CompanySearch from "./companySearch";
import { searchProjectDelivery } from "../../../store/Dashboard/GP/staff/actions";

function ProjectDelivery(props) {
  const {
    data,
    navigateToOpportunityPage,
    expandPermission,
    type,
    onePanelExpanded,
  } = props;
  const [shouldExpand, setShouldExpand] = useState(false);
  const [searchParm, setSearchParm] = useState("");
  const [isClearable, setIsClearable] = useState(false);
  const [projectDeliveryTableData, setProjectDeliveryTableData] = useState();

  useEffect(() => {
    if (
      props.projectDelivery &&
      !props.projectDelivery.data.deliveredProjects
    ) {
      setProjectDeliveryTableData(props.projectDelivery.data.deliveredProjects);
    }
  }, [props.projectDelivery]);

  useEffect(() => {
    if (
      props.isSuccessSearchProjectDelivery &&
      !props.isSuccessProjectDelivery
    ) {
      setProjectDeliveryTableData(props.searchedProjectDelivery.data);
    }
  }, [props.isSuccessSearchProjectDelivery]);

  useEffect(() => {
    if (!props.isSuccessSearchProjectDelivery && props.projectDelivery) {
      setProjectDeliveryTableData(props.projectDelivery.data.deliveredProjects);
    }
  }, [props.isSuccessSearchProjectDelivery, props.projectDelivery]);

  if (!data) {
    return null;
  }

  const handleExpand = (v) => {
    setShouldExpand(v);
  };

  return (
    <Row className="m-0">
      <Col className="p-3 m-0">
        <h2 className="h4">
          5. Project Delivery - <span>Completed Projects &amp; Impact</span>
        </h2>
        <div className="widget-layout">
          <Row md={4}>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={ProjectsCompleted} alt="projects-completed" />
                </div>
              </div>
              <div></div>
              <span>Projects Completed</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.count}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={investment} alt="icon-investment" />
                </div>
              </div>
              <div></div>
              <span>Investment Costs</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                &pound;
                {data.investment
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={savings} alt="icon-savings" />
                </div>
              </div>
              <div></div>
              <span>Savings &pound; PA</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                &pound;
                {data.savingPA
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={payback} alt="icon-payback" />
                </div>
              </div>
              <div></div>
              <span>Payback Time (Years)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.savingPayback.toFixed(2)}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <span className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={energy} alt="icon-energy" />
                </div>
              </span>
              <div></div>
              <span className="fullwidth-flex">Energy Savings (kWh)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.energySaving
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <span className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={water} alt="icon-water" />
                </div>
              </span>
              <div></div>
              <span className="fullwidth-flex">Water Savings (m³)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.waterSaving
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={co2} alt="icon-co2" />
                </div>
              </div>
              <div></div>
              <span>Carbon Savings (t)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.carbonSaving
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
          </Row>
          {shouldExpand && (
            <Row className="mt-2 mb-5">
              <Col className="expand-panel">
                <CompanySearch
                  searchLabel={"List of Reports Completed"}
                  list={props.businessAccounts}
                  searchParm={searchParm}
                  setSearchParm={setSearchParm}
                  isClearable={isClearable}
                  setIsClearable={setIsClearable}
                  searchFunction={props.searchProjectDelivery}
                  type={type}
                  onePanelExpanded={onePanelExpanded}
                  widgetType={"completedProjects"}
                />
                <Row>
                  <Col className="tbl-wrapper p-0">
                    {projectDeliveryTableData &&
                    projectDeliveryTableData.length > 0 ? (
                      <Table bordered className="mb-0">
                        <thead>
                          <tr>
                            <th className="caller">Company Name</th>
                            <th className="text-center">Investments</th>
                            <th className="text-center">Savings &pound; PA</th>
                            <th className="text-center">
                              Payback Time (Years)
                            </th>
                            <th className="text-center">
                              Energy Savings (kWh)
                            </th>
                            <th className="text-center">Water Savings (m³)</th>
                            <th className="text-center">Carbon Savings (t)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectDeliveryTableData.length > 0 &&
                            projectDeliveryTableData.map((mCall, index) => (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  <a
                                    className="company-name-link"
                                    onClick={() => navigateToOpportunityPage(mCall.businessAccountId)}
                                  >
                                    {mCall.businessAccountName}
                                  </a>
                                </td>
                                <td>
                                  &pound;
                                  {mCall.investment
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  &pound;
                                  {mCall.savingPA
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>{mCall.savingPayback.toFixed(2)}</td>
                                <td>
                                  {mCall.energySaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {mCall.waterSaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {mCall.carbonSaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p className="text-center h5">No records available</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {expandPermission == "True" && (
            <Row className="expand-row custom-styles">
              <Col className="text-right">
                <Button
                  className="panel-op"
                  color="link"
                  onClick={() => {
                    handleExpand(!shouldExpand);
                  }}
                >
                  <img
                    src={shouldExpand ? collapse : expand}
                    alt="expand/collapse"
                  />
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
}
ProjectDelivery.propTypes = {
  businessAccounts: PropTypes.any,
  searchProjectDelivery: PropTypes.func,
  projectDelivery: PropTypes.any,
  searchedProjectDelivery: PropTypes.any,
  isSuccessProjectDelivery: PropTypes.any,
  isSuccessSearchProjectDelivery: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    businessAccounts: state.GPDashboard.businessAccounts,
    searchProjectDelivery: state.GPDashboard.searchProjectDelivery,
    searchedProjectDelivery: state.GPDashboard.searchedProjectDelivery,
    projectDelivery: state.GPDashboard.projectDelivery,
    isSuccessProjectDelivery: state.GPDashboard.isSuccessProjectDelivery,
    isSuccessSearchProjectDelivery:
      state.GPDashboard.isSuccessSearchProjectDelivery,
  };
};

export default connect(mapStateToProps, { searchProjectDelivery })(
  ProjectDelivery
);
