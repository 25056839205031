import { useState, useEffect } from "react";
import { Row, Col, Button, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import co2 from "../../../assets/images/icons/gp2-icons/CarbonFootprint.svg";
import ProposalsFinalised from "../../../assets/images/icons/gp-dashboard-icons/ProposalsFinalised.svg";
import energy from "../../../assets/images/icons/gp2-icons/Energy_1.svg";
import water from "../../../assets/images/icons/gp2-icons/Water_1.svg";
import payback from "../../../assets/images/icons/gp2-icons/Payback.svg";
import savings from "../../../assets/images/icons/gp2-icons/Savings.svg";
import investment from "../../../assets/images/icons/gp2-icons/TotalInvestment.svg";
import collapse from "../../../assets/images/icons/gp2-icons/collapse.svg";
import expand from "../../../assets/images/icons/gp2-icons/expand.svg";
import { numberFormatToWhole } from "../../../helpers";
import CompanySearch from "./companySearch";
import { searchProjectFinacing } from "../../../store/Dashboard/GP/staff/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ProjectFinance(props) {
  const {
    data,
    navigateToOpportunityPage,
    expandPermission,
    type,
    onePanelExpanded,
  } = props;
  const [shouldExpand, setShouldExpand] = useState(false);
  const [searchParm, setSearchParm] = useState("");
  const [isClearable, setIsClearable] = useState(false);
  const [projectFinancingTableData, setProjectFinancingTableData] = useState();

  useEffect(() => {
    if (
      props.projectFinancing &&
      !props.projectFinancing.data.investmentProposals
    ) {
      setProjectFinancingTableData(
        props.projectFinancing.data.investmentProposals
      );
    }
  }, [props.projectFinancing]);

  useEffect(() => {
    if (
      props.isSuccessSearchProjectFinancing &&
      !props.isSuccessProjectFinancing
    ) {
      setProjectFinancingTableData(props.searchedProjectFinancing.data);
    }
  }, [props.isSuccessSearchProjectFinancing]);

  useEffect(() => {
    if (!props.isSuccessSearchProjectFinancing && props.projectFinancing) {
      setProjectFinancingTableData(
        props.projectFinancing.data.investmentProposals
      );
    }
  }, [props.isSuccessSearchProjectFinancing, props.projectFinancing]);

  if (!data) {
    return null;
  }

  const handleExpand = (v) => {
    setShouldExpand(v);
  };
  return (
    <Row className="m-0">
      <Col className="p-3 m-0">
        <h2 className="h4">
          4. Project Financing - <span>Investment Opportunities</span>
        </h2>
        <div className="widget-layout">
          <Row md={4}>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={ProposalsFinalised} alt="proposals-finalised" />
                </div>
              </div>
              <div></div>
              <span>Proposals Finalised</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.count}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={investment} alt="icon-investment" />
                </div>
              </div>
              <div></div>
              <span>Investment</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                &pound;
                {data.investment
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={savings} alt="icon-savings" />
                </div>
              </div>
              <div></div>
              <span>Savings &pound; PA</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                &pound;
                {data.savingPA
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={payback} alt="icon-payback" />
                </div>
              </div>
              <div></div>
              <span>Payback Time (Years)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.savingPayback.toFixed(2)}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <span className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={energy} alt="icon-energy" />
                </div>
              </span>
              <div></div>
              <span className="fullwidth-flex">Energy Savings (kWh)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.energySaving
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <span className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={water} alt="icon-water" />
                </div>
              </span>
              <div></div>
              <span className="fullwidth-flex">Water Savings (m³)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.waterSaving
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={co2} alt="icon-co2" />
                </div>
              </div>
              <div></div>
              <span>Carbon Savings (t)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.carbonSaving
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
          </Row>
          {shouldExpand && (
            <Row className="mt-2 mb-5">
              <Col className="expand-panel">
                <CompanySearch
                  searchLabel={"List of Reports Completed"}
                  list={props.businessAccounts}
                  searchParm={searchParm}
                  setSearchParm={setSearchParm}
                  isClearable={isClearable}
                  setIsClearable={setIsClearable}
                  searchFunction={props.searchProjectFinacing}
                  type={type}
                  onePanelExpanded={onePanelExpanded}
                  widgetType={"projectFinance"}
                />
                <Row>
                  <Col className="tbl-wrapper p-0">
                    {projectFinancingTableData &&
                    projectFinancingTableData.length > 0 ? (
                      <Table bordered className="mb-0">
                        <thead>
                          <tr>
                            <th className="col-left-padding">Company Name</th>
                            <th className="col-left-padding">Investment</th>
                            <th className="col-left-padding">
                              Savings &pound; PA
                            </th>
                            <th className="col-left-padding">
                              Payback Time (Years)
                            </th>
                            <th className="col-left-padding">
                              Energy Savings (kWh)
                            </th>
                            <th className="col-left-padding">
                              Water Savings (m³)
                            </th>
                            <th className="col-left-padding">
                              Carbon Savings (t)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectFinancingTableData.length > 0 &&
                            projectFinancingTableData.map((mCall, index) => (
                              <tr key={index}>
                                <td>
                                  {mCall.reportsCount === 0 ? (
                                  <OverlayTrigger
                                    key={index}
                                    placement="top"
                                    trigger="click"
                                    rootClose={true}
                                    overlay={<Tooltip className="custom-tooltip">This report is currently draft and is subject to change until the point it is finalised</Tooltip>}
                                  >
                                    <a className="company-name-link" >
                                      {mCall.businessAccountName}
                                    </a>
                                  </OverlayTrigger>
                                ) : (
                                  <a
                                    className="company-name-link"
                                    onClick={() => navigateToOpportunityPage(mCall.businessAccountName, "projectFinancing")}
                                  >
                                    {mCall.businessAccountName}
                                  </a>
                                )}
                                </td>
                                <td>
                                  &pound;
                                  {mCall.investment
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  &pound;
                                  {mCall.savingPA
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>{mCall.savingPayback.toFixed(2)}</td>
                                <td>
                                  {mCall.energySaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {mCall.waterSaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {mCall.carbonSaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p className="text-center h5">No records available</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {expandPermission == "True" && (
            <Row className="expand-row custom-styles">
              <Col className="text-right">
                <Button
                  className="panel-op"
                  color="link"
                  onClick={() => {
                    handleExpand(!shouldExpand);
                  }}
                >
                  <img
                    src={shouldExpand ? collapse : expand}
                    alt="expand/collapse"
                  />
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
}
ProjectFinance.propTypes = {
  businessAccounts: PropTypes.any,
  searchProjectFinancing: PropTypes.func,
  isSuccessSearchProjectFinancing: PropTypes.any,
  projectFinancing: PropTypes.any,
  isSuccessProjectFinancing: PropTypes.any,
  isSuccessSearchProjectFinancing: PropTypes.any,
  searchedProjectFinancing: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    businessAccounts: state.GPDashboard.businessAccounts,
    searchProjectFinancing: state.GPDashboard.searchProjectFinancing,
    isSuccessSearchProjectFinancing:
      state.GPDashboard.isSuccessSearchProjectFinancing,
    projectFinancing: state.GPDashboard.projectFinancing,
    isSuccessProjectFinancing: state.GPDashboard.isSuccessProjectFinancing,
    isSuccessSearchProjectFinancing:
      state.GPDashboard.isSuccessSearchProjectFinancing,
    searchedProjectFinancing: state.GPDashboard.searchedProjectFinancing,
  };
};

export default connect(mapStateToProps, { searchProjectFinacing })(
  ProjectFinance
);
