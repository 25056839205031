import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalBody,
  Container,
  ModalHeader,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import preview from "../../../assets/images/icons/preview.svg";
import collapse from "../../../assets/images/icons/gp2-icons/collapse.svg";
import expand from "../../../assets/images/icons/gp2-icons/expand.svg";
import CompanySearch from "./companySearch";
import carbonMentor from "../../../assets/images/icons/1-carbon-mentor.svg";
import {
  searchCarbonMentorCalls,
  getSASToken,
} from "../../../store/Dashboard/GP/staff/actions";

const CarbonMentorCall = (props) => {
  const {
    setCarbonMentorExpanded,
    onePanelExpanded,
    data,
    expandPermission,
    carbonMentorExpanded,
    reportsExpanded,
    type,
  } = props;
  const [shouldExpand, setShouldExpand] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [searchParm, setSearchParm] = useState("");
  const [isClearable, setIsClearable] = useState(false);
  const [mentorCallTableData, setMentorCallTableData] = useState();
  const [isIframValidUrl, setIsIframValidUrl] = useState(false);
  const [videoSasUrl, setVideoSasUrl] = useState("");

  const toggle = () => setIsShowVideo(!isShowVideo);

  useEffect(() => {
    if (
      props.mentorDetailCalls &&
      props.mentorDetailCalls.data.mentorCallsDetails
    ) {
      setMentorCallTableData(props.mentorDetailCalls.data.mentorCallsDetails);
      props.getSASToken();
    }
  }, [props.mentorDetailCalls]);

  useEffect(() => {
    if (
      props.isSuccessSearchCarbonMentorCall &&
      !props.isSuccessMentorDetailCalls
    ) {
      setMentorCallTableData(props.searchedCarbonMentorCalls.data);
    }
  }, [props.isSuccessSearchCarbonMentorCall]);

  useEffect(() => {
    if (
      !props.isSuccessSearchCarbonMentorCall &&
      props.isSuccessMentorDetailCalls
    ) {
      setMentorCallTableData(props.mentorDetailCalls.data.mentorCallsDetails);
    }
  }, [props.isSuccessSearchCarbonMentorCall, props.isSuccessMentorDetailCalls]);

  const handleExpand = (v) => {
    setShouldExpand(v);
    setCarbonMentorExpanded(v);
  };

  const handleClick = (url) => {
    setIsShowVideo(!isShowVideo);
    setSelectedVideoUrl(url);

    if (!props.sasToken) {
      props.getSASToken();
    } else {
      setVideoSasUrl(url + "?" + props.sasToken.data.message);
    }
  };

  const handleIframeLoad = (e) => {
    setIsIframValidUrl(true);
  };

  if (!data) {
    return null;
  }
  return (
    <Col className="p-3 position-relative" md={onePanelExpanded ? 12 : 6}>
      <h2 className="h4">
        1. Carbon Mentor Calls - <span>Support &amp; Education Impact </span>
      </h2>
      <div className="widget-layout min-height-100">
        <Row>
          <Col sm={8} md={6} className="caption-wrapper">
              <img src={carbonMentor} alt="icon-savings" width="150vh" height="auto"/>
          </Col>
          <Col sm={4} md={6} className="value-wrapper">
            <span className="value fullwidth-flex">
              {data.completedMentorCallCount}
            </span>
            <span className="text center caption m-0 fullwidth-flex">
              Carbon mentor calls completed
            </span>
          </Col>
        </Row>
        {shouldExpand && (
          <Row className="mt-2 mb-5">
            <Col className="expand-panel">
              <CompanySearch
                searchLabel={"Calls completed"}
                list={props.businessAccounts}
                searchParm={searchParm}
                setSearchParm={setSearchParm}
                searchFunction={props.searchCarbonMentorCalls}
                isClearable={isClearable}
                setIsClearable={setIsClearable}
                customClass={carbonMentorExpanded && reportsExpanded}
                type={type}
                onePanelExpanded={onePanelExpanded}
                widgetType={"mentorcall"}
              />
              <Row>
                <Col className="tbl-wrapper p-0">
                  {mentorCallTableData && mentorCallTableData.length > 0 ? (
                    <Table bordered className="mb-0">
                      <thead>
                        <tr>
                          <th className="caller">Caller Company Name</th>
                          <th className="caller">Preview Recording</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mentorCallTableData.length > 0 &&
                          mentorCallTableData.map((mCall, i) => (
                            <tr key={i}>
                              <td>{mCall.businessAccountName}</td>
                              <td>
                                <img
                                  src={preview}
                                  alt="Preview video"
                                  onClick={() => handleClick(mCall.videoLink)}
                                  width={25}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p className="text-center h5">No records available</p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {expandPermission == "True" && (
          <Row className="expand-row">
            <Col className="text-right">
              <Button
                className="panel-op"
                color="link"
                onClick={() => {
                  handleExpand(!shouldExpand);
                }}
              >
                <img
                  src={shouldExpand ? collapse : expand}
                  alt="expand/collapse"
                />
              </Button>
            </Col>
          </Row>
        )}

        {isShowVideo && (
          <Container>
            <Modal funk={true} isOpen={isShowVideo} className="modal-lg">
              <ModalHeader toggle={toggle}></ModalHeader>
              <ModalBody>
                <div className="main-container">
                  <div className="video-container">
                    <iframe
                      src={videoSasUrl}
                      onLoad={(e) => handleIframeLoad(e)}
                    ></iframe>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Container>
        )}
      </div>
    </Col>
  );
};
CarbonMentorCall.propTypes = {
  businessAccounts: PropTypes.any,
  isSuccessSearchCarbonMentorCall: PropTypes.any,
  isSuccessMentorDetailCalls: PropTypes.any,
  searchCarbonMentorCalls: PropTypes.func,
  mentorDetailCalls: PropTypes.any,
  searchedCarbonMentorCalls: PropTypes.any,
  getSASToken: PropTypes.func,
  sasToken: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    businessAccounts: state.GPDashboard.businessAccounts,
    isSuccessSearchCarbonMentorCall:
      state.GPDashboard.isSuccessSearchCarbonMentorCall,
    isSuccessMentorDetailCalls: state.GPDashboard.isSuccessMentorDetailCalls,
    searchCarbonMentorCalls: state.GPDashboard.searchCarbonMentorCalls,
    mentorDetailCalls: state.GPDashboard.mentorDetailCalls,
    searchedCarbonMentorCalls: state.GPDashboard.searchedCarbonMentorCalls,
    getSASToken: state.GPDashboard.getSASToken,
    sasToken: state.GPDashboard.sasToken,
  };
};
export default connect(mapStateToProps, {
  searchCarbonMentorCalls,
  getSASToken,
})(CarbonMentorCall);
