import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import bold from "../../assets/images/icons/Bold.svg";
import italic from "../../assets/images/icons/Italic.svg";
import underline from "../../assets/images/icons/Underline.svg";
import fontSize from "../../assets/images/icons/FontSize.svg";
import colorPicker from "../../assets/images/icons/ColorPicker.svg";
import link from "../..//assets/images/icons/Link.svg";
import left from "../../assets/images/icons/Left.svg";
import justify from "../../assets/images/icons/Justify.svg";
import unordered from "../../assets/images/icons/Unordered.svg";
import ordered from "../../assets/images/icons/Ordered.svg";
import outdent from "../../assets/images/icons/Outdent.svg";
import indent from "../../assets/images/icons/Indent.svg";
import image from "../../assets/images/icons/ImageEditor.svg";
import { useLocation } from "react-router-dom";


const RichTextEditor = (props) => {
  const { editorState, onEditorStateChange, fieldsDisabled } = props;

  
  const location = useLocation();
  const { pathname } = location;

  let imageShown = pathname.includes("report-settings") ? true : false;
  const [showImage, setShowImage] = useState(imageShown);
  const [uploadedImages, setUploadedImages] = useState([]);


  const getFileBase64 = (file, callback) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => callback(reader.result);
  reader.onerror = error => {};
};

const imageUploadCallback = file => new Promise(
  (resolve, reject) => getFileBase64(
    file,
    data => resolve({ data: { link: data } })
  )
);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      readOnly={fieldsDisabled}
      wrapperClassName={fieldsDisabled ? "disable-editor" : "wrapper-class"}
      editorClassName="editor-class"
      toolbarClassName="main-toolbar"
      toolbar={{
        options: [
          "fontSize",
          "inline",
          "colorPicker",
          "link",
          "image",
          "textAlign",
          "list",
        ],
        fontSize: {
          icon: fontSize,
          className: "toolbar-style1",
        },
        inline: {
          options: ["bold", "italic", "underline"],
          bold: { icon: bold, className: "toolbar-style1" },
          italic: { icon: italic, className: "toolbar-style1" },
          underline: { icon: underline, className: "toolbar-style1" },
        },
        colorPicker: {
          icon: colorPicker,
          className: "toolbar-style1",
        },
        link: {
          showOpenOptionOnHover: true,
          defaultTargetOption: "_blank",
          options: ["link"],          
          link: {
            icon: link,
            className: "toolbar-style1"
          },
        },
        image: {
          icon: image,
          className: showImage ? "toolbar-style1" : "image-style",
          urlEnabled: true,
          uploadEnabled: true,
          uploadCallback: imageUploadCallback,
          alignmentEnabled: true,
          previewImage: true,
          inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          alt: { present: false, mandatory: false },
          defaultSize: {
            height: "auto",
            width: "auto",
          },
        },
        textAlign: {
          options: ["left", "justify"],
          left: { icon: left, className: "toolbar-style1" },
          justify: { icon: justify, className: "toolbar-style1" },
        },
        list: {
          options: ["unordered", "ordered", "indent", "outdent"],
          unordered: { icon: unordered, className: "toolbar-style1" },
          ordered: { icon: ordered, className: "toolbar-style1" },
          outdent: { icon: outdent, className: "toolbar-style1" },
          indent: { icon: indent, className: "toolbar-style1" },
        },
      }}
    />
  );
};

export default RichTextEditor;
