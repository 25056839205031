import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Table } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import co2 from "../../../assets/images/icons/gp2-icons/CarbonFootprint.svg";
import energy from "../../../assets/images/icons/gp2-icons/Energy_1.svg";
import water from "../../../assets/images/icons/gp2-icons/Water_1.svg";
import payback from "../../../assets/images/icons/gp2-icons/Payback.svg";
import savings from "../../../assets/images/icons/gp2-icons/Savings.svg";
import investment from "../../../assets/images/icons/gp2-icons/TotalInvestment.svg";
import audits from "../../../assets/images/icons/Audits2.svg";
import collapse from "../../../assets/images/icons/gp2-icons/collapse.svg";
import expand from "../../../assets/images/icons/gp2-icons/expand.svg";
import { numberFormatToWhole } from "../../../helpers";
import CompanySearch from "./companySearch";
import { searchBuildingAudits } from "../../../store/Dashboard/GP/staff/actions";

const BuildingAudits = (props) => {
  const {
    data,
    navigateToOpportunityPage,
    expandPermission,
    type,
    onePanelExpanded,
  } = props;
  const [shouldExpand, setShouldExpand] = useState(false);
  const [searchParm, setSearchParm] = useState("");
  const [isClearable, setIsClearable] = useState(false);
  const [buildingAuditsTableData, setBuildingAuditTableData] = useState();

  const handleExpand = (v) => {
    setShouldExpand(v);
  };

  useEffect(() => {
    if (props.buildingAudits && !props.buildingAudits.data.buildingAudits) {
      setBuildingAuditTableData(props.buildingAudits.data.buildingAudits);
    }
  }, [props.buildingAudits]);

  useEffect(() => {
    if (props.isSuccessSearchBuildingAudits && !props.isSuccessBuildingAudits) {
      setBuildingAuditTableData(props.searchBuidingAudits.data);
    }
  }, [props.isSuccessSearchBuildingAudits]);

  useEffect(() => {
    if (!props.isSuccessSearchBuildingAudits && props.buildingAudits) {
      setBuildingAuditTableData(props.buildingAudits.data.buildingAudits);
    }
  }, [props.isSuccessSearchBuildingAudits, props.buildingAudits]);

  if (!data) {
    return null;
  }

  return (
    <Row className="me-0 mt-4">
      <Col className="p-3">
        <h2 className="h4">
          3. Building Audits - <span>Potential Untapped Impact</span>
        </h2>
        <div className="widget-layout">
          <Row md={4}>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2 p-0">
                <div className="icon">
                  <img src={audits} alt="icon-audits" />
                </div>
              </div>
              <div></div>
              <span className="fullwidth-flex">
                Audits Completed
              </span>
              <div></div>
              <span className="fullwidth-flex sub-caption">{data.count}</span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={investment} alt="icon-investment" />
                </div>
              </div>
              <div></div>
              <span className="fullwidth-flex">Investment</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                &pound;
                {data.investment
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={savings} alt="icon-savings" />
                </div>
              </div>
              <div></div>
              <span className="fullwidth-flex">Savings &pound; PA</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                &pound;
                {data.savingPA
                  .toFixed(2)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
              <div className="icon-wrapper mb-2">
                <div className="icon">
                  <img src={payback} alt="icon-payback" />
                </div>
              </div>
              <div></div>
              <span className="fullwidth-flex">Payback Time (Years)</span>
              <div></div>
              <span className="fullwidth-flex sub-caption">
                {data.savingPayback.toFixed(2)}
              </span>
            </Col>
            <Col className="text-center col-lg mb-md-2">
                <div className="icon-wrapper mb-2">
                  <div className="icon">
                    <img src={energy} alt="icon-energy" />
                  </div>
                </div>
                <div></div>
                <span className="fullwidth-flex">Energy Savings (kWh)</span>
                <div></div>
                <span className="fullwidth-flex sub-caption">
                  {data.energySaving
                    .toFixed(2)
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </Col>
            <Col className="text-center col-lg mb-md-2">
                <div className="icon-wrapper mb-2">
                  <div className="icon">
                    <img src={water} alt="icon-water" />
                  </div>
                </div>
                <div></div>
                <span className="fullwidth-flex">Water Savings (m³)</span>
                <div></div>
                <span className="fullwidth-flex sub-caption">
                  {data.waterSaving
                    .toFixed(2)
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </Col>
            <Col className="text-center col-lg mb-md-2">
                <div className="icon-wrapper mb-2">
                  <div className="icon">
                    <img src={co2} alt="icon-co2" />
                  </div>
                </div>
                <div></div>
                <span className="fullwidth-flex">Carbon Savings (t)</span>
                <div></div>
                <span className="fullwidth-flex sub-caption">
                  {data.carbonSaving
                    .toFixed(2)
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </Col>
          </Row>
          {shouldExpand && (
            <Row className="mt-2 mb-5">
              <Col className="expand-panel">
                <CompanySearch
                  searchLabel={"List of Reports Completed"}
                  list={props.businessAccounts}
                  searchParm={searchParm}
                  setSearchParm={setSearchParm}
                  searchFunction={props.searchBuildingAudits}
                  isClearable={isClearable}
                  setIsClearable={setIsClearable}
                  type={type}
                  onePanelExpanded={onePanelExpanded}
                  widgetType={"building"}
                />
                <Row>
                  <Col className="tbl-wrapper p-0">
                    {buildingAuditsTableData &&
                    buildingAuditsTableData.length > 0 ? (
                      <Table bordered className="mb-0">
                        <thead>
                          <tr>
                            <th className="col-left-padding">Company Name</th>
                            <th className="col-left-padding">Investment</th>
                            <th className="col-left-padding">
                              Savings &pound; PA
                            </th>
                            <th className="col-left-padding">
                              Payback Time (Years)
                            </th>
                            <th className="col-left-padding">
                              Energy Savings (kWh)
                            </th>
                            <th className="col-left-padding">
                              Water Savings (m³)
                            </th>
                            <th className="col-left-padding">
                              Carbon Savings (t)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {buildingAuditsTableData.length > 0 &&
                            buildingAuditsTableData.map((mCall, index) => (
                              <tr key={index}>
                                <td>
                                  {mCall.reportsCount === 0 ? (
                                      <OverlayTrigger
                                        key={index}
                                        placement="top"
                                        trigger="click"
                                        rootClose={true}
                                        overlay={
                                          <Tooltip className="custom-tooltip">
                                            This report is currently draft and is subject to change until the point it is finalised
                                          </Tooltip>
                                        }
                                      >
                                        <a className="company-name-link">{mCall.businessAccountName}</a>
                                      </OverlayTrigger>
                                    ) : (
                                      <a
                                        className="company-name-link"
                                        onClick={() =>
                                          navigateToOpportunityPage(mCall.businessAccountName, "buildingAudits")
                                        }
                                      >
                                        {mCall.businessAccountName}
                                      </a>
                                    )
                                  }
                                </td>
                                <td>
                                  &pound;
                                  {mCall.investment
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  &pound;
                                  {mCall.savingPA
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>{mCall.savingPayback.toFixed(2)}</td>
                                <td>
                                  {mCall.energySaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {mCall.waterSaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {mCall.carbonSaving
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p className="text-center h5">No records available</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {expandPermission === "True" && (
            <Row className="expand-row custom-styles">
              <Col className="text-right">
                <Button
                  className="panel-op"
                  color="link"
                  onClick={() => {
                    handleExpand(!shouldExpand);
                  }}
                >
                  <img
                    src={shouldExpand ? collapse : expand}
                    alt="expand/collapse"
                  />
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};
BuildingAudits.propTypes = {
  businessAccounts: PropTypes.any,
  searchBuildingAudits: PropTypes.any,
  searchBuidingAudits: PropTypes.any,
  isSuccessSearchBuildingAudits: PropTypes.any,
  isSuccessBuildingAudits: PropTypes.any,
  buildingAudits: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    businessAccounts: state.GPDashboard.businessAccounts,
    searchBuildingAudits: state.GPDashboard.searchBuildingAudits,
    searchBuidingAudits: state.GPDashboard.searchBuidingAudits,
    isSuccessSearchBuildingAudits:
      state.GPDashboard.isSuccessSearchBuildingAudits,
    isSuccessBuildingAudits: state.GPDashboard.isSuccessBuildingAudits,
    buildingAudits: state.GPDashboard.buildingAudits,
  };
};

export default connect(mapStateToProps, { searchBuildingAudits })(
  BuildingAudits
);
